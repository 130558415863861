// import { navigate } from 'gatsby'

// export default () => {
//       navigate('/privacy-policy-ft')
//     }

import { useEffect } from 'react';
import { navigate } from 'gatsby';

export default () => {
  useEffect(() => {
    navigate('/privacy-policy-ft/');
  }, []);
  return null;
};